import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MediaThumbnail } from '@services/media-services/models';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { MediaTileComponent } from '@enginuity/product/organisms/media/media-tile/media-tile.component';

@Component({
  selector: 'app-media-gallery-box',
  standalone: true,
  imports: [CdkDropList, CdkDrag, MediaTileComponent],
  templateUrl: './media-gallery-box.component.html',
  styleUrl: './media-gallery-box.component.scss',
})
export class MediaGalleryBoxComponent {
  @Input() enableDelete: boolean = true;
  @Input() images: MediaThumbnail[] = [];
  @Input() videos: MediaThumbnail[] = [];
  @Output() onSort = new EventEmitter<MediaThumbnail[]>();
  @Output() onDelete = new EventEmitter<MediaThumbnail>();

  imageDrop(event: CdkDragDrop<any, any>) {
    moveItemInArray(this.images, event.previousIndex, event.currentIndex);
    this.onSort.emit(this.images);
  }

  onEntryDelete(entry: MediaThumbnail) {
    this.onDelete.emit(entry);
  }
}
